import { Link } from "gatsby"
import React from "react";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby"

function Features(){
   /* const data = useStaticQuery(graphql`
    {
      servicearea: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "service-area.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)*/

    return(
        <div className="container-fluid">
            <div class="row features text-center">
            <div class="col-md-4">
                <h1 className="features-title">INDUSTRY EXPERTS</h1>
                <p className="features-description">Each member of our staff has been chosen for their<br /> wealth of experience in industry</p>
            </div>
            <div class="col-md-4">
                <h1 className="features-title">DURABLE GLASS</h1>
                <p className="features-description">We only use ‘Toughened Glass’. This glass is ‘Heat<br /> Soaked’ to ensure its strength and durability.</p>
            </div>
            <div class="col-md-4">
                <h1 className="features-title">MADE IN AUSTRALIA</h1>
                <p className="features-description">Our materials are all made in Australia. This helps<br /> ensure the safety of the structure and your loved ones.</p>
            </div>
            </div>
        </div>
    )
}

export default Features;