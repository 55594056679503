import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";
import Features from "../components/features";
import ImageBox from "../components/image-box";
import Testimonial from "../components/testimonial";
import Badges from "../components/badges";
import ServiceArea from "../components/service-area";
import FindUs from "../components/find-us";
import StickyButton from "../components/sticky-button";
//import Image from "../components/image"
import SEO from "../components/seo"
import "../style/bootstrap.min.css";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
//import Button from 'react-bootstrap/Button';
//import Jumbotron from 'react-bootstrap/Button';
//import Container from 'react-bootstrap/Button';

function Index(){
  return(
    <>
    <Header />
    <Jumbotron>
          <div className="page-header">
          <h1 className="IndexTitle" >GLASS FENCING, STAIRWAYS & MORE</h1>
          <h2 className="IndexSubtitle">“Hands down the best glass pool fencing company in Sydney”
<br />– Samantha Brooks</h2>
          <div class="text-center">
          <Button className="IndexButton" href="tel:0290993940">Call: 02 9099 3940</Button>
          </div>
          </div>
  
</Jumbotron>
      <Features />
      <Testimonial />
      <ImageBox />
      <FindUs />
      <Badges />
      <ServiceArea />
      <Footer />
      <StickyButton />
    </>
  )
}
export default Index;
