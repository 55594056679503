import { Link } from "gatsby"
import React from "react";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby"

function Badges(){
    const data = useStaticQuery(graphql`
    {
      badges: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "contractor-partners.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1324) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return(
        <div className="container">
            <div className="text-center badges">
                <h1 className="title">Our Achivements</h1>
            <Img
          fluid={data.badges.childImageSharp.fluid}
          alt="badges"
        />
            </div>
        </div>
    )
}

export default Badges;