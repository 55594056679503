import { Link } from "gatsby"
import React from "react";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby"

function ImageBox(){
   const data = useStaticQuery(graphql`
    {
        homeimage: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "1C.jpg" }
      ) {
        childImageSharp {
          fluid (maxWidth:800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)

    return(
        <div className="container-fluid">
            <div class="row image-box">
            <div class="col-md-6">
              <Img fluid={data.homeimage.childImageSharp.fluid} alt="1C" />
            </div>
            <div class="col-md-6">
                <div class="container-fluid image-box-textarea">
                <h4 className="image-box-title">HANDPICKED STAFF</h4>
                <h2 className="image-box-title">HANDPICKED STAFF DELIVERING AMAZING RESULTS</h2>
                <p className="image-box-description">Pacific Glass Pool Fencing has a staff of glass glazers that have been chosen to do the the job because of their wealth of experience in the industry. We only employ members who are highly qualified for the job at hand.<br /><br /> Our tradesman are extremely dedicated to providing all clients with all the information they need for their next fencing project. We take extreme care of every client and work exclusively to achieve the highest result after our clients needs.</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ImageBox;