import { Link } from "gatsby"
import React from "react";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby"

function FindUs(){
    const data = useStaticQuery(graphql`
    {
      findus: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "proof.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1091) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return(
        <div className="container">
          <h1 className="title">FIND US ON</h1>
            <Img
          fluid={data.findus.childImageSharp.fluid}
          alt="badges"
        />
        </div>
    )
}

export default FindUs;