import { Link } from "gatsby"
import React from "react";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby"

function ServiceArea(){
    const data = useStaticQuery(graphql`
    {
      servicearea: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "service-area.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return(
        <div className="container-fluid px-0">
            <Img
          fluid={data.servicearea.childImageSharp.fluid}
          alt="badges"
        />
        </div>
    )
}

export default ServiceArea;